import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Fade,
  IconButton,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'

import { useTranslation } from 'react-i18next'

import { closePasskeyDialog } from '../../../store/passkeys/actions'
import { selectPasskeyState } from '../../../store/passkeys/selectors'

import PasskeyIcon from './passkey.svg'
import SlideUp from '../../Transitions/SlideUp'

import { PasskeyStateType } from '../../../store/passkeys/reducer'
import useEntryQuery from '../../../hooks/useEntryQuery'
import { POST_MESSAGE_TYPE } from '../../../utils/helpers/network-service'
import { selectCurrentUser } from '../../../store/app/selectors'
import { generatePasskeysRegistration } from '../../../utils/api/auth'
import { actionRegisterPasskeys } from '../../../store/auth/actions'
import { getDeviceName, isIOS } from '../../../utils/helpers/helpers'
import { openNotification } from '../../../store/notification/actions'

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    textAlign: 'center',
    alignItems: 'center',
    padding: '8px 24px 24px 24px',
    overflowY: 'initial',
  },
  contentBox: {
    width: '100%',
    maxHeight: '100%',
  },
  flex: {
    display: 'flex',
  },
  controlDialog: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  controlFrameDialog: {
    position: 'absolute',
    top: 0,
  },
  checkboxRemember: {
    color: (theme.palette as any).textDialog,
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: '8px 16px',
    '& button': {
      marginLeft: '8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
    },
  },
  shortText: {
    margin: 'auto',
    width: '225px',
  },
  backButton: {
    marginRight: 'auto',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  boldText: {
    fontWeight: 600,
    fontSize: '1.2rem',
  },
  normalText: {
    margin: '16px 0',
  },
  submitButton: {
    margin: '16px',
  },
  pointer: {
    cursor: 'pointer',
  },
  textAlignStart: {
    textAlign: 'start',
  },
  btn: {
    marginTop: 24,
    width: '100%',
    display: 'flex',
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 20,
    flex: 1,
    textAlign: 'center',
    paddingTop: 12,
  },
  txtFrameInform: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.secondary,
    paddingTop: 24,
  },
  txtInform: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.secondary,
    paddingTop: 24,
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
  },
  btnAskLater: { fontSize: 14, color: theme.palette.primary.main, cursor: 'pointer' },
  btnSkip: {
    fontSize: 14,
    cursor: 'pointer',
  },
  passkeyIcon: {
    marginRight: 8,
  },
  iconAdd: {
    width: 16,
    height: 16,
    color: theme.palette.primary.contrastText,
    margin: '4px 0',
  },
}))

const Passkeys = () => {
  const { t } = useTranslation()
  const dispatch: AsyncDispatch = useDispatch()
  const classes = useStyles()
  const { query } = useEntryQuery()

  const currentUser = useSelector(selectCurrentUser)
  const passkeyDialogState: PasskeyStateType = useSelector(selectPasskeyState)
  const theme = useTheme()
  const [isCreatingPasskey, setIsCreatingPasskey] = useState(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const isIframeMode = query?.is_iframe === 'true'
  const isIFrameMobile = query?.is_mobile === 'true'

  const handleClose = () => {
    dispatch(closePasskeyDialog({ isOpened: true }))
  }

  useEffect(() => {
    if (isIframeMode) {
      window.parent.postMessage(
        { type: POST_MESSAGE_TYPE.IFRAME_PASSKEY, visible: passkeyDialogState.visible },
        '*',
      )
    }
  }, [passkeyDialogState.isError, passkeyDialogState.visible])

  useEffect(() => {
    const handleListener = (event: MessageEvent) => {
      const { type, success, message } = event.data
      if (type === POST_MESSAGE_TYPE.WEB_APP_PASSKEY_CREATE) {
        if (success) {
          dispatch(closePasskeyDialog({ isOpened: true }))
        } else {
          dispatch(
            openNotification({
              message: t(`${message}`),
              messageType: 'code',
              type: 'error',
            }),
          )
        }
        setIsCreatingPasskey(false)
      }
    }
    if (isIOS() && isIFrameMobile) {
      window.addEventListener('message', handleListener)
    }
    return () => {
      window.removeEventListener('message', handleListener)
    }
  }, [])

  const registerPasskeys = async () => {
    try {
      setIsCreatingPasskey(true)
      if (isIOS() && isIFrameMobile) {
        window.parent.postMessage(
          {
            type: POST_MESSAGE_TYPE.PASSKEY_CREATE,
            userId: currentUser.id,
            email: currentUser.email,
            userName: currentUser.name,
          },
          '*',
        )
      } else {
        const userId = currentUser.id
        const optionsResponse = await generatePasskeysRegistration({
          userId,
          email: currentUser.email,
          userName: currentUser.name,
        })
        const options = optionsResponse.data.data
        const { startRegistration } = await import('@simplewebauthn/browser')
        const assertionResponse = await startRegistration(options)
        dispatch(actionRegisterPasskeys(assertionResponse, userId.toString(), getDeviceName()))
          .then(() => {
            dispatch(closePasskeyDialog({ isOpened: true }))
          })
          .finally(() => {
            setIsCreatingPasskey(false)
          })
      }
    } catch (error) {
      setIsCreatingPasskey(false)
    }
  }

  return (
    <Dialog
      open={passkeyDialogState.visible}
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={(fullScreen && SlideUp) || Fade}
      hideBackdrop={isIframeMode}
      PaperProps={{
        style: {
          boxShadow: isIframeMode ? 'none' : undefined,
        },
      }}
    >
      {!isIframeMode && (
        <>
          <Box className={classes.title}>{t('login-form__login-faster-by-passkeys')}</Box>
          <div className={cn(classes.controlDialog, classes.flex)}>
            <IconButton
              className={cn(classes.closeButton, classes.flex)}
              onClick={() => handleClose()}
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>
        </>
      )}

      <DialogContent className={cn(classes.dialogContent)}>
        <div className={classes.contentBox}>
          <Box
            className={cn({
              [classes.txtInform]: !isIframeMode,
              [classes.txtFrameInform]: isIframeMode,
            })}
          >
            {t('login-form__login-by-passkey-inform')}
          </Box>
          <Button
            className={cn(classes.btn)}
            color="primary"
            type="button"
            variant="contained"
            onClick={registerPasskeys}
          >
            {isCreatingPasskey ? (
              <CircularProgress size={16} className={classes.iconAdd} />
            ) : (
              <>
                <img src={PasskeyIcon} loading="lazy" className={classes.passkeyIcon} />
                {t('login-form__create-new-passkey')}
              </>
            )}
          </Button>
        </div>
        <Box className={classes.footer}>
          <Box
            data-test="not-now-btn"
            className={cn(classes.btnAskLater)}
            color="primary"
            style={{ pointerEvents: isCreatingPasskey ? 'none' : 'auto' }}
            onClick={() => handleClose()}
          >
            {t('common-title__not-now')}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

Passkeys.whyDidYouRender = true

export default reduxForm({ form: 'passkey' })(Passkeys)
